<template>
    <!-- 导航 -->
    <page-head :title="store.state.teamData.name" />
    <content-bg>
        <template v-slot:content>
            <div class="menuList">
                <div v-if="showData && showData.showList"
                     v-for="(item,index) in showData.showList"
                     class="menuItem" @click="menuClick(item)">
                    <menu-card>
<!--                        <template v-slot:icon><img class="icon" :src="item.icon" alt=""></template>-->
                        <template v-slot:icon><img class="icon" src="@i/school/school.svg" alt=""></template>
                        <template v-slot:label>
                            <p class="label">{{ item.schoolName }}</p>
                        </template>
                    </menu-card>
                </div>

            </div>
        </template>
    </content-bg>
    <pagination-com :current-page="showData.page" :total-page="showData.totalPage" @changePage="changePage"></pagination-com>
</template>

<script>
import {useRouter} from "vue-router";
import {getCurrentInstance, reactive, toRefs, watch} from "vue";
import {useStore} from "vuex";

export default {
    name: "menu",
    setup() {

        const router = useRouter()
        const {proxy} = getCurrentInstance()
        const store = useStore()

        const state = reactive({

            menuList: [],

            // 显示的菜单，分页
            page: {
                pageNum: 1,
                pageSize: 15
            },
            showData: {}
        });

        watch(() => store.state.identifyData, (val, old) => {
            console.log(val, old);
            if (!val.type) return false;
            const params = {
                ...val,
                ...state.showData,
            }
            proxy.$utils.identifyFn({
                type: val.type,
                data: params,
                changePage: changePage,
                router: menuClick
            })
        })

        const getSchoolList = async () => {
            const {
                code,
                data
            } = await proxy.$server.getSchoolList(state.query);
            if (code === 200) {
                state.menuList = data;
            }
            state.showData = proxy.$utils.paginationFn(state.menuList, state.page.pageNum, state.page.pageSize);
        }

        getSchoolList();

        const changePage = (page) => {
            state.page.pageNum = page;
            state.showData = proxy.$utils.paginationFn(state.menuList, state.page.pageNum, state.page.pageSize);
        }

        const menuClick = (item) => {
            console.log(item)
            router.push({
                path: '/school/details',
                name: 'schoolDetails',
                query: {
                    id: item.id
                }
            })
        }


        return {
            ...toRefs(state),
            changePage,
            menuClick,
            store
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .menuItem {
    width: 240px;
    height: 210px;
    margin-right: 25px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 32px;
    text-align: center;

    &:nth-child(5n+1) {
        margin-left: 30px;
    }

    .cardItem {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .icon {
        width: 90px;
        height: 90px;
        margin-top: 26px;
        margin-bottom: 22px;
    }

    .label {
        font-size: 26px;
        line-height: 36px;
        padding-left: 20px;
        padding-right: 20px;
        font-family: PingFangMedium;
        color: #FFFFFF;
    }
}

::v-deep.pagination {
    position: absolute;
    left: 592px;
    top: 978px;
}

.menuList {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    width: 100%;
}
</style>